import React, { useEffect, useState } from "react"
import { useAppContext } from "../../contexts/app-context"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { getEntityFullName, getEntityInitials } from "../../utils/string"
import { useNavigate } from "react-router-dom"
import { ALLROUTES } from "../../routes"
import { usePermissionMutation } from "../../utils/mutations"
import { toast } from "react-toastify"

const Permission = () => {
  const [students, setStudents] = useState([])
  const nav = useNavigate()
  const { dataAllStudent } = useAppContext()
  const addPermissionMutation =
    usePermissionMutation.useStudentPermissionMutation()

  useEffect(() => {
    console.log("all student ", dataAllStudent)
  }, [])
  console.log("all data student ", dataAllStudent)
  const [checkData, setCheckData] = useState()
  const handlePermission = () => {
    addPermissionMutation.mutate(students, {
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          toast.success(`Permission allowed`)
          // refetchRoles?.()
        } else {
          console.error(data)
        }
      },
      onError: axiosError => {
        toast.error(`Permission Denied...`)
        console.error(axiosError?.response?.data)
      }
    })
  }

  const handleChange = e => {
    setStudents?.([
      ...students,
      { student: parseInt(e?.target?.value), calendar: e?.target?.checked }
    ])
  }

  //console.log("student..s", students)

  return (
    <>
      <Container fluid className="py-2">
        <div className=" m-4" style={{ fontSize: "14px", fontStyle: "italic" }}>
          <div
            className=" m-4"
            style={{ fontSize: "20px", fontStyle: "normal" }}
          >
            Student Permissions
          </div>
          <div className="m-4" style={{ lineHeight: "1", fontStyle: "normal" }}>
            Allow specific students the ability to help manage teacher-only
            tasks. Students with added permissions can access the web workspace
            but will only have the ability to see and edit the features that you
            select here.
          </div>
          <div className="font-italic">
            <span style={{ marginLeft: "15%" }}>Calendar</span>
            <span style={{ marginLeft: "40px" }}>
              Note: Additional permission settings will eventually be added
              here!
            </span>
          </div>
        </div>
        <Row>
          <Col>
            <Form.Group className="form-group px-1 pt-1">
              <div>
                {dataAllStudent?.data?.map((data, i) => (
                  <div className="user-list-item d-flex" key={i}>
                    <Form.Check>
                      {/* <img
                      src={data?.profile_picture}
                      width={"20px"}
                      height={"20px"}
                    /> */}

                      <Form.Check.Label className="d-flex">
                        <div
                          className={`image${
                            !!!data?.profile_picture ? " no-image" : ""
                          }`}
                          data-initials={getEntityInitials(data)}
                        >
                          {!!data?.profile_picture && (
                            <img src={data?.profile_picture} alt="" />
                          )}
                        </div>

                        {/* {`${data?.first_name} ${data?.last_name}`} */}
                        <div className="details d-flex flex-column flex-grow-1">
                          <div className="title">{getEntityFullName(data)}</div>
                          <div className="type">{data?.user_type}</div>
                        </div>
                        <Form.Check.Input
                          defaultChecked={
                            data?.permissions?.calendar == null ||
                            data?.permissions?.calendar == false
                              ? false
                              : data?.permissions?.calendar
                          }
                          id={data?.id}
                          type={`checkbox`}
                          value={data?.id}
                          style={{ marginLeft: "50px" }}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                ))}
              </div>
            </Form.Group>
          </Col>
        </Row>
        {dataAllStudent?.data?.length ? (
          <Row style={{ marginTop: "50px" }}>
            <div className="d-flex">
              <Col className="d-flex justify-content-start m-4">
                <Button
                  variant="light"
                  onClick={() => nav(ALLROUTES.dashboardContainer)}
                >
                  Back
                </Button>
              </Col>
              <Col className="d-flex justify-content-end m-4">
                <Button
                  onClick={() => {
                    handlePermission()
                  }}
                >
                  Approve
                </Button>
              </Col>
            </div>
          </Row>
        ) : null}
      </Container>
    </>
  )
}

export default Permission
