import React, { useEffect, useMemo, useRef, useState } from "react"
import { Button, Form } from "react-bootstrap"

import { ALLICONS } from "../../assets"
import AppFormSelect from "../../components/form/select"
import { useRolesContext } from "./context/roles-context"
import { useRoleMutation } from "../../utils/mutations"
import RoleItemMembers from "./role-item-members"
import { RoleItemContextProvider } from "./context/role-item-context"
import { APPCONFIG } from "../../app-config"
import { toast } from "react-toastify"
import {
  applicableThespianPoints,
  debounce,
  fieldErrorMessages
} from "../../utils/common"
import { useAppContext } from "../../contexts/app-context"
import AddNewRoleCategory from "./role-add-category"

const RoleItem = ({ roleItemData = {} }) => {
  const {
    activeRoleCategoryTab,
    dataRoleCategories,
    isFetchingRoleCategories,
    refetchRoles
  } = useRolesContext()
  const fieldNameRef = useRef()

  const savedUserData = sessionStorage.getItem(
    APPCONFIG.sessionVariables.USER_DATA
  )
  const userData = JSON.parse(savedUserData)

  const { selectedProduction } = useAppContext()
  const { showAddCategoryModal, setShowAddCategoryModal, lastCategoryAdd, setLastCategoryAdd, categoryChangeId, setCategoryChangeId } = useRolesContext()
  const [cat, setCat] = useState(null)


  const { id: roleId, category, members, name, thespian_point } = roleItemData


  const [points, setPoints] = useState(
    thespian_point ??
    applicableThespianPoints({
      selectedProduction,
      dataRoleCategories,
      activeRoleCategoryTab,
      categoryId: category
    })
  )

  useEffect(() => {
    if (name === APPCONFIG.apiConst.newRoleName) {

      fieldNameRef?.current?.select()
    }
  }, [])
  useEffect(() => {
    lastCategoryAdd && roleItemData.id === categoryChangeId && handleModifyRoleSpec("category", lastCategoryAdd, true)
  }, [lastCategoryAdd])

  const modifyRoleMutation = useRoleMutation.useModifyRoleMutation()
  const modifyRoleMutate = useMemo(
    () =>
      debounce((spec, modifyParams, options) => {
        if (spec !== "name" || modifyParams?.name?.length > 0) {
          modifyRoleMutation.mutate(modifyParams, options)
        }
      }, 500),
    []
  )
  const handleModifyRoleSpec = (spec, newValue, saveModified) => {
    console.log("sp", spec, "..new ", newValue)
    setCat(newValue)
    setLastCategoryAdd(null)
    const modifyRoleParams = {
      roleId
    }
    modifyRoleParams[spec] = newValue
    if (spec === "category") {

      const categoryFilter = dataRoleCategories?.data?.find(
        roleCatItem =>
          roleCatItem?.type === activeRoleCategoryTab?.slug && roleCatItem.id === parseInt(newValue)
      )
      const newPoints = categoryFilter.thespian_point ?? 8
      setPoints(newPoints)
      modifyRoleParams.thespian_point = newPoints
    }
    saveModified && saveRoles(spec, modifyRoleParams)

  }
  const saveRoles = (spec, modifyRoleParams) => {
    modifyRoleMutate(spec, modifyRoleParams, {
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          refetchRoles?.()

        } else {
          refetchRoles?.()
          console.error(data)
        }
      },
      onError: axiosError => {
        toast.error(
          `Update failed. ${fieldErrorMessages(axiosError?.response?.data)}`
        )
        console.error(axiosError?.response?.data)
        refetchRoles?.()
      }
    })
  }
  const deleteRoleMutation = useRoleMutation.useDeleteRoleMutation()
  const handleDeleteRole = () => {
    if (window.confirm("Are you sure you wish to delete this role?")) {
      deleteRoleMutation.mutate(
        { roleId },
        {
          onSuccess: ({ data, status }) => {
            if (status === 204) {
              toast.success(`The role has been deleted`)
              refetchRoles?.()
            } else {
              console.error(data)
            }
          },
          onError: axiosError => {
            toast.error(`Failed to delete role.`)
            console.error(axiosError?.response?.data)
          }
        }
      )
    }
  }

  const handleCat = cate => {
    setCat(cate)
    setCategoryChangeId(roleItemData.id)
    setShowAddCategoryModal(true)
  }

  return userData?.user_type === "Teacher" ? (
    <RoleItemContextProvider>
      <div className="role-item" data-userscount={members?.length || 0}>
        <div className="role-item-contents-wrap">
          <Button
            variant="light"
            className="sq"
            title="Delete role"
            onClick={handleDeleteRole}
          >
            <ALLICONS.Delete />
          </Button>
          <Form.Group className="d-flex">
            <Form.Control
              ref={fieldNameRef}
              name={`role-name`}
              placeholder="Title"
              defaultValue={name}
              onBlur={(e) => {
                handleModifyRoleSpec("name", e.target.value, true)
              }}
              onChange={event => {
                handleModifyRoleSpec("name", event?.target?.value, false)
              }}
            />
          </Form.Group>
          <AppFormSelect
            ariaLabel="Category"
            className="category-selector"
            name={`role-category`}
            options={
              !!dataRoleCategories && !isFetchingRoleCategories
                ? dataRoleCategories?.data
                  ?.filter(
                    roleCatItem =>
                      roleCatItem?.type === activeRoleCategoryTab?.slug
                  )
                  ?.map(roleCatItem => {
                    return {
                      value: roleCatItem?.id,
                      label: roleCatItem?.name
                    }
                  })
                : []
            }
            selectedItem={category}
            onChangeCallback={event => {
              handleModifyRoleSpec("category", event?.target?.value, true)
            }}
          />
          <Button
            variant="light"
            style={{
              marginReight: "10px",
              marginLeft: "0px",
              paddingLeft: "3px",
              paddingRight: "3px"
            }}
            onClick={() => {
              // setCat(category)
              // console.log("setcat..", cat, category)
              // setShowAddCategoryModal(true)
              handleCat(category)
            }}
          >
            + Category
          </Button>
          <Form.Group className="d-flex">
            <Form.Control
              placeholder="Points"
              name={`role-points`}
              defaultValue={
                thespian_point ?? "8"
                // applicableThespianPoints({
                //   selectedProduction,
                //   dataRoleCategories,
                //   activeRoleCategoryTab,
                //   categoryId: category
                // })
              }
              onBlur={(e) => {
                handleModifyRoleSpec("thespian_point", e.target.value, true)
              }}
              value={points}
              onChange={event => {
                setPoints(event?.target?.value)
                handleModifyRoleSpec("thespian_point", event?.target?.value, false)
              }}
            />
          </Form.Group>
          <RoleItemMembers
            roleItemData={{
              ...roleItemData,
              ...(!!fieldNameRef?.current?.value
                ? { name: fieldNameRef?.current?.value }
                : {})
            }}
          />
        </div>
      </div>
      <AddNewRoleCategory category={cat} />
    </RoleItemContextProvider>
  ) : null
}
export default RoleItem
