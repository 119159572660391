import React, { useEffect, useState } from "react"
import { Accordion, Form, FormCheck } from "react-bootstrap"
import { APPCONFIG } from "../../app-config"
import { ALLICONS } from "../../assets"
import { useAppContext } from "../../contexts/app-context"
import { getEntityFullName, getEntityInitials } from "../../utils/string"
import { useProductionCalendarUsersContext } from "./context/production-calendar-users-context"

const ProductionCalendarUserItem = ({
  listItem,
  membersSelected = [],
  roleType,
  isChecked,
  onMemberSelectCallback,
  slug
}) => {

  const { membersSelectable } = useProductionCalendarUsersContext()
  const memberId = listItem?.id * 1

  return (
    <div className="user-list-item">
      {(membersSelectable || roleType) && (
        <Form.Check
          type={`checkbox`}
          id={`role-member-to-add-${memberId}`}
          className={`me-4 ms-3`}
          checked={
            roleType ? isChecked : membersSelected?.findIndex((val) => val.id === memberId && val.type === slug) > -1
          }
          onChange={event => {
            onMemberSelectCallback?.(
              event?.target?.checked,
              roleType ? listItem : memberId
            )
          }}
        />
      )}
      {!roleType && (
        <div
          className={`image${!!!listItem?.profile_picture && !!!listItem?.image
            ? " no-image"
            : ""
            }`}
          data-initials={getEntityInitials(listItem)}
        >
          {!!listItem?.profile_picture && (
            <img src={listItem?.profile_picture} alt="" />
          )}
          {!!listItem?.image && <img src={listItem?.image} alt="" />}
        </div>
      )}
      {roleType ? (
        <div className="details d-flex flex-column flex-grow-1">
          <div className="title">
            <b>{listItem}</b>
          </div>
        </div>
      ) : (
        <div className="details d-flex flex-column flex-grow-1">
          <div className="title">{getEntityFullName(listItem)}</div>
          <div className="type">{listItem?.user_type}</div>
        </div>
      )}
      {!!!membersSelectable && (
        <div className="handle">
          <ALLICONS.SortHandle />
        </div>
      )}
    </div>
  )
}

const ScreenDashProductionCalendarUsersList = ({
  memberList = [],
  membersSelected = [],
  showRoles,
  groupMembers,
  onMemberSelectCallback,
  activeSlug
}) => {
  const { setRoleNewMembersToAdd } = useProductionCalendarUsersContext()

  const [listItems, setListItems] = useState(memberList)
  const [member, setMember] = useState([])
  const [allSelected, setAllSelected] = useState({ Group: false, Student: false, Teacher: false, Parent: false })

  const [roles, setRoles] = useState([])

  const [searchKeyword, setSearchKeyword] = useState("")

  useEffect(() => {
    console.log("this is selected members", membersSelected)

  }, [membersSelected])

  const filterStudents = () => {
    if (!!!memberList) return
    console.log("[filterStudents]")
    setListItems(
      memberList
        ?.filter(studRecord =>
          !!searchKeyword
            ? getEntityFullName(studRecord)
              .toLowerCase()
              .indexOf(searchKeyword.toLowerCase()) > -1
            : true
        )
        ?.sort((a, b) =>
          getEntityFullName(a)
            ?.toLowerCase()
            .localeCompare(getEntityFullName(b)?.toLowerCase())
        ) || []
    )
  }

  const handleRoles = (flag, item) => {
    console.log("#&&", flag, item)
    if (flag) {
      setRoles([...roles, item])
      let arr = listItems.filter(x => x.role === item).map(x => x.id)
      console.log(arr)
      onMemberSelectCallback(true, arr)
    } else {
      setRoles(roles.filter(x => x !== item))
      let arr = listItems.filter(x => x.role === item).map(x => x.id)
      onMemberSelectCallback(false, arr)
    }
  }

  useEffect(() => {
    if (APPCONFIG.debugGlobal) console.log("[useEffect] searchKeyword")
    filterStudents()
  }, [searchKeyword])

  useEffect(() => {
    if (APPCONFIG.debugGlobal) console.log("[useEffect] memberList")
    filterStudents()
  }, [memberList])

  useEffect(() => {
    setRoleNewMembersToAdd?.([])
  }, [searchKeyword])

  const handleSelectAll = e => {
    console.log("sI", listItems)

    let arr = listItems.map(data => data?.id)
    console.log("A", arr)
    if (allSelected[activeSlug]) {
      allSelected[activeSlug] = !allSelected[activeSlug]
      setAllSelected(allSelected)
      onMemberSelectCallback(false, arr)
    } else {
      allSelected[activeSlug] = !allSelected[activeSlug]
      setAllSelected(allSelected)
      onMemberSelectCallback(true, arr)
    }
  }

  return (
    <>
      <Form.Group className="form-group">
        <Form.Control
          className="form-control--search"
          name={`role-search-members-to-add`}
          placeholder="Search "
          defaultValue={searchKeyword}
          onKeyUp={event => {
            console.log("Typing keyword...")
            setSearchKeyword(event?.target?.value || "")
          }}
        />
      </Form.Group>
      {!['crew', 'cast'].includes(activeSlug) ? <div>
        <ProductionCalendarUserItem
          listItem={"Select All"}
          slug={activeSlug}
          roleType
          isChecked={
            allSelected[activeSlug]
          }
          membersSelected={roles}
          onMemberSelectCallback={handleSelectAll}
        />
      </div> : <div style={{ marginBottom: 15 }}></div>}
      {listItems?.length === 0 ? (
        <p className="my-4">No results</p>
      ) : (
        <div className={`user-list`}>
          {showRoles
            ? [...new Set(listItems.map(x => x.role))]
              .flatMap(x => {
                return [x, ...listItems.filter(y => y.role === x)]
              })
              .map((listItem, j) => {
                return listItem?.id ? (
                  <ProductionCalendarUserItem
                    slug={activeSlug}
                    listItem={listItem}
                    key={j}
                    membersSelected={membersSelected}
                    onMemberSelectCallback={onMemberSelectCallback}
                  />
                ) : (
                  <div className="details d-flex flex-column flex-grow-1">
                    <div className="title">
                      <b>{listItem}</b>
                    </div>
                  </div>
                )
              })
            : listItems.map((listItem, j) => (
              <ProductionCalendarUserItem
                slug={activeSlug}
                listItem={listItem}
                key={j}

                membersSelected={membersSelected}
                onMemberSelectCallback={onMemberSelectCallback}
              />
            ))}
        </div>
      )}
      <div className="flex-grow-1"></div>
    </>
  )
}
export default ScreenDashProductionCalendarUsersList
