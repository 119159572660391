import React from "react"
import { ClipLoader } from "react-spinners"

const ShowUpLoader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        zIndex: "9999",
        position: "fixed",
        left: "0px",
        top: "50%",
        // display: "none",
        textAlign: "center"
      }}
    >
      <ClipLoader
        color={"#545b5a"}
        // loading={loading}
        //cssOverride={override}

        size={75}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  )
}

export default ShowUpLoader
