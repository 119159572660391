import AnchorButton from "./anchorButton";

const CardPlan = ({ plan }) => {
    return (<div className="col-6 auto pt-2" >
        <div className="card">
            <div className="card-body card-content">
                <div className="container">
                    <div className="col">
                        <div className="row">
                            <h6 className="card-title title-content">
                                {plan.plan_name}
                            </h6>
                            <p style={{ fontSize: "14px" }}>{plan.plan_description}</p>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="form-check">
                                    <div className="row">
                                        <div className="col-4 fw-medium">
                                        </div>
                                        <div className="col-4">
                                            <AnchorButton href={plan.link} text={"Get Plan"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default CardPlan;