import { APPCONFIG } from "../app-config"
import { numberOrdinal, zPad } from "./number"
//import moment, { format } from "moment"
import moment from "moment-timezone"
export const formatDateToString = dateStringObject => {
  const dateObject = !!dateStringObject
    ? typeof dateStringObject === "string"
      ? new Date(dateStringObject)
      : dateStringObject // is object
    : new Date()
  return `${dateObject.getFullYear()}-${zPad(
    dateObject.getMonth() + 1,
    2
  )}-${zPad(dateObject.getDate(), 2)}`
}

export const formatDateToStringUS = dateString => {
  const dateObject = new Date(dateString)
  let d = formatDateToString(dateObject)
  d = d.split("-")
  return `${d[1]}/${d[2]}/${d[0]}`
}
export const formatDateToFormat = (dateString, format = "MMM D") => {
  const dateObject = new Date(dateString)
  const dateMoment = moment(dateObject.toDateString())
  const d = dateMoment.format("YYYY-MM-DD").split("-")
  const str = format
    .replace("DAY", APPCONFIG.dayNames[dateObject.getDay()])
    .replace("DD", zPad(d[2], 2))
    .replace("D", d[2] * 1)
    .replace("O", numberOrdinal(d[2]))
    .replace("MMMM", APPCONFIG.monthNames[d[1] - 1])
    .replace("MMM", APPCONFIG.monthNames[d[1] - 1]?.substring(0, 3))
    .replace("MM", zPad(d[1], 2))
    .replace("YYYY", d[0])
    .replace("hh", dateObject.getHours())
    .replace("mm", zPad(dateObject.getMinutes(), 2))
  return str
}

export const getUTCTime = (time) => {
  const localDate = new Date(time)
  const tm = moment(
    localDate
  );
  const utctime = tm.utc().format("HH:mm");
  return utctime
}
export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate()
}

export const firstLastDatesOfMonth = dateObject => {
  const firstDateObject = new Date(dateObject)
  firstDateObject.setDate(1)
  const lastDateObject = new Date(
    dateObject.getFullYear(),
    dateObject.getMonth() + 1,
    0
  )
  return [
    formatDateToString(firstDateObject),
    formatDateToString(lastDateObject)
  ]
}

export const tConvert = (date, time, showTimezone) => {
  const utcDate = date + "T" + time + "Z";

  return convertZuluToLocal12HourFormat(utcDate, showTimezone);
}
function convertZuluToLocal12HourFormat(zuluTimeString, showTimezone) {
  // Create a new Date object using the Zulu time string
  const date = new Date(zuluTimeString);
  const timeZoneName = showTimezone ? "short" : undefined
  // Use Intl.DateTimeFormat to format the date in a 12-hour (AM-PM) notation, specific to the local time zone
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName
  };

  // Format the date according to the local time zone and specified options
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;
}

export const getTimeAmPm = (dateTimeString, appendUTC = true) => {

  const t = new Date(`${dateTimeString}${appendUTC ? " UTC" : ""}`)

  return t.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: undefined
  })
}

export const convertLocalDateTimeToUTC = dateTimeString => {
  console.log({ dateTimeString });
  const d = new Date(new Date(`${dateTimeString} 12:00:00`).toString().substr(0, 25))
  return `${d.getFullYear()}-${zPad(d.getMonth() + 1, 2)}-${zPad(
    d.getDate(),
    2
  )} ${zPad(d.getHours(), 2)}:${zPad(d.getMinutes(), 2)}:${zPad(
    d.getSeconds(),
    2
  )}`
}
