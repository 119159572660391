import React, { useState } from "react"
import { useRolesContext } from "./context/roles-context"
import VCenteredModal from "../../components/modal/vcentered-modal"
import { useRoleMutation } from "../../utils/mutations"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { toast } from "react-toastify"
import AppFormSelect from "../../components/form/select"

const AddNewRoleCategory = ({ category }) => {
  const [c, setC] = useState(category)

  const {
    showAddCategoryModal,
    setShowAddCategoryModal,
    activeRoleCategoryTab,
    dataRoleCategories,
    isFetchingRoleCategories,
    refetchRoles,
    refetchCategory,
    setLastCategoryAdd
  } = useRolesContext()

  const addCategory = useRoleMutation.useAddCategoriesMutation()
  const [formData, setFormData] = useState({
    name: "",
    type: activeRoleCategoryTab?.slug,
    thespian_point: 8
  })


  const setFormDataItem = (fieldName, value) => {
    setFormData?.(prevData => ({
      ...prevData,
      [fieldName]: value
    }))
  }


  const handleAddCategory = () => {
    addCategory.mutate(formData, {
      onSuccess: ({ data, status }) => {
        if ([200, 201].includes(status)) {
          // setLoading(false)
          toast.success(
            `The custom category have been added for ${activeRoleCategoryTab?.title}.`
          )
          refetchRoles?.()
          refetchCategory?.()
          setLastCategoryAdd(data.id)
          setShowAddCategoryModal(false)
        } else {
          console.error(data)
        }
      },
      onError: axiosError => {
        toast.error(`Please fill all required fields`)
        console.error(axiosError?.response?.data)
      }
    })
  }
  return (
    <>
      <VCenteredModal
        id={`production-calendar-add-conflict-modal`}
        modalProps={{
          className: `modal-production-calendar w-max modal-production-calendar-add-conflict`,
          onHide: () => {
            setShowAddCategoryModal(false)
          },
          show: showAddCategoryModal,
          size: "sm"
        }}
        ModalFooterButtonsOnRight={() => (
          <>
            <Button
              //   variant="light"
              /*disabled={roleNewMembersToAdd?.length < 1}*/
              onClick={handleAddCategory}
            >
              Create custom category
            </Button>
          </>
        )}
        closeButtonOnFooterText={"Cancel"}
      >
        <Container fluid className="py-2">
          <Row>
            <Col className={"d-flex flex-column pe-4"}>
              <h4 className="fs-10 mb-4">
                {`Create new ${activeRoleCategoryTab?.title}`} Category
              </h4>
            </Col>
          </Row>
          {/* <Row>
            <span style={{ marginBottom: "3px" }}>Calegory</span>
            <AppFormSelect
              ariaLabel="Category"
              className="category-selector"
              name={`role-category`}
              options={
                !!dataRoleCategories && !isFetchingRoleCategories
                  ? dataRoleCategories?.data
                      ?.filter(
                        roleCatItem =>
                          roleCatItem?.type === activeRoleCategoryTab?.slug
                      )
                      ?.map(roleCatItem => {
                        return {
                          value: roleCatItem?.id,
                          label: roleCatItem?.name
                        }
                      })
                  : []
              }
              selectedItem={category}
              style={{ marginBottom: "3px" }}
            />
          </Row> */}
          <Row>
            <Form.Group>
              <Form.Label>Custom Category</Form.Label>

              <Form.Control
                className="flex-grow-1"
                name={`name`}
                as={"input"}
                onChange={event => {
                  setFormDataItem("name", event?.target?.value || "")
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Thespian pointy</Form.Label>

              <Form.Control
                className="flex-grow-1"
                name={`thespian_point`}
                as={"input"}
                value={formData.thespian_point}
                type="number"
                onChange={event => {
                  setFormDataItem("thespian_point", event?.target?.value || "")
                }}
              />
            </Form.Group>
          </Row>
        </Container>
      </VCenteredModal>
    </>
  )
}

export default AddNewRoleCategory
