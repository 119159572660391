import React, { useEffect, useState } from "react"
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap"
import DatePicker from "react-datepicker"
import moment from "moment-timezone"
import VCenteredModal from "../../components/modal/vcentered-modal"
import WrapperMainContentSidebar from "../../components/wrappers/dashboard/main-content-sidebar"
import {
  convertLocalDateTimeToUTC,
  formatDateToFormat,
  getTimeAmPm,
  getUTCTime
} from "../../utils/date"
import { useProductionCalendarContext } from "./context/production-calendar-context"
import ScreenDashProductionCalendarUsersList from "./production-calendar-users-list"
import { useAppContext } from "../../contexts/app-context"
import { useProductionsQueries } from "../../utils/queries"
import { useProductionMutation } from "../../utils/mutations"
import { toast } from "react-toastify"
import { fieldErrorMessages } from "../../utils/common"
import AppFormSelect from "../../components/form/select"
import { APPCONFIG } from "../../app-config"
import { getEntityFullName, getEntityInitials } from "../../utils/string"
import ShowUpLoader from "../../components/ShowUpLoader"
import { capitalizeFirstLetter, utilSelectedData, utilProcessGroupAndUser, getCorrectDate, getCrewOrCastMembers } from "./utils/utils"
const ProductionCalendarModalAddEvent = () => {
  const {
    isUserLoggedIn,
    dataAllStaff,
    dataAllStudents,
    dataProductions,
    selectedProduction
  } = useAppContext()
  const {
    refetchAllEvents,
    dataAllParents,
    dataGroups,
    showModalAddEvent,
    setShowModalAddEvent,
    showModalAddEventOverConflicts,
    setShowModalAddEventOverConflicts,
    dataAddEventOverConflicts,
    setDataAddEventOverConflicts,
    modalEditEventData,
    setModalEditEventData
  } = useProductionCalendarContext()



  const [groupData, setGroupData] = useState([dataGroups])

  const entryOptions = [
    APPCONFIG.apiConst.EntryOptions.OPEN_TO_ALL,
    APPCONFIG.apiConst.EntryOptions.INVITATION_ONLY
  ].map(entryItem => ({
    label: entryItem,
    value: entryItem
  }))

  const userTypeFilters = [
    {
      slug: "Student",
      title: "Students"
    },
    {
      slug: "Parent",
      title: "Parents"
    },
    {
      slug: "Teacher",
      title: "Teachers"
    },
    {
      slug: "Group",
      title: "Groups"
    },
    {
      slug: "cast",
      title: "Cast"
    },
    {
      slug: "crew",
      title: "Crew"
    }
  ]
  const [activeUserTypeFilter, setActiveUserTypeFilter] = useState(
    userTypeFilters[0]
  )
  const [loading, setLoading] = useState(false)
  const [selectedData, setSelectedData] = useState([])

  let formDataInitialValue = {
    user: [],
    production: selectedProduction?.id,
    entry: entryOptions?.[0]?.value
  }
  if (modalEditEventData?.id) {

    formDataInitialValue = {
      ...modalEditEventData,
      production: selectedProduction?.id,
      groups: modalEditEventData?.groups?.map(user => user) || [],
      participants:
        modalEditEventData?.participants?.map(user => user?.id) || [],
      start_date: getCorrectDate(modalEditEventData?.date, modalEditEventData?.start_time),
      end_date: getCorrectDate(modalEditEventData?.date, modalEditEventData?.end_time)
    }


  }
  const [formData, setFormData] = useState(formDataInitialValue)

  const {
    data: dataApplicationStatusByRole,
    isFetching: isFetchingApplicationStatusByRole
  } = useProductionsQueries.useApplicationStatusByRoleQuery({
    enabled: isUserLoggedIn && !!showModalAddEvent,
    productionId: selectedProduction?.id,
    rolesType: activeUserTypeFilter?.slug
  })


  useEffect(() => {
    !showModalAddEvent && setSelectedData([])
    if (showModalAddEvent === true) {
      setFormData(formDataInitialValue) // reset data on open/close of modal
    } else {
      setModalEditEventData(null)
    }
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [showModalAddEvent])
  useEffect(() => {
    if (modalEditEventData) {
      const groups = modalEditEventData?.groups?.map(gr => { return { id: gr, type: "Group" } })
      const sParticipants = modalEditEventData?.participants?.map(par => { return { id: par.id, type: capitalizeFirstLetter(par.user_type) } })
      setSelectedData([...groups, ...sParticipants])
    }
  }, [modalEditEventData])
  useEffect(() => {
    if (['cast', 'crew'].includes(activeUserTypeFilter?.slug)) {
      const arrayWithOutDuplicates = getCrewOrCastMembers(dataApplicationStatusByRole, selectedData, activeUserTypeFilter?.slug)
      setSelectedData([...arrayWithOutDuplicates])
    }
  }, [dataApplicationStatusByRole])
  const [participants, setParticipants] = useState([])
  // const [group, setSelectedData] = useState([])

  useEffect(() => {

    if (formData?.groups) {
      formData?.groups.map(data => {
        if (!formData?.participants.includes(data))
          formData?.participants.push(data)
      })
    }
    setParticipants(formData?.participants)
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [
    formData?.participants
    //formData?.groups
  ])

  const setFormDataItem = (fieldName, value) => {
    setFormData?.(prevData => ({
      ...prevData,
      [fieldName]: value,
      ...(fieldName === "start_date"
        ? {
          date: formatDateToFormat(value, "YYYY-MM-DD"),
          start_time: formatDateToFormat(value, "hh:mm")
        }
        : {}),
      ...(fieldName === "end_date"
        ? {
          end_time: formatDateToFormat(value, "hh:mm")
        }
        : {})
    }))
  }

  const addEventMutation = useProductionMutation.useAddEventMutation()
  const updateEventMutation = useProductionMutation.useUpdateEventMutation()
  const checkEventConflictsMutation =
    useProductionMutation.useCheckEventConflictsMutation()
  const confirmedCreateEvent = payload => {

    // return
    addEventMutation.mutate(
      { ...payload },
      {
        onSuccess: ({ data, status }) => {
          if ([200, 201].includes(status)) {
            toast.success(`The event have been added.`)
            setShowModalAddEvent(false)
            setShowModalAddEventOverConflicts(false)
            refetchAllEvents?.()
          } else {
            console.error(data)
            setLoading(false)
          }
        },
        onError: axiosError => {
          const errorMessage =
            axiosError?.response?.data?.non_field_errors?.[0] ??
            `Failed to add event. ${fieldErrorMessages(
              axiosError?.response?.data
            ).join(", ")}`
          toast.error(errorMessage?.toString())
          setLoading(false)
        }
      }
    )
  }

  const confirmedUpdateEvent = payload => {

    updateEventMutation.mutate(
      { ...payload },
      {
        onSuccess: ({ data, status }) => {
          if ([200, 201].includes(status)) {

            toast.success(`The event have been updated.`)
            setShowModalAddEvent(false)
            setShowModalAddEventOverConflicts(false)
            refetchAllEvents?.()
          } else {
            console.error(data)
          }
        },
        onError: axiosError => {
          const errorMessage =
            axiosError?.response?.data?.non_field_errors?.[0] ??
            `Failed to update event. ${fieldErrorMessages(
              axiosError?.response?.data
            ).join(", ")}`
          toast.error(errorMessage?.toString())
        }
      }
    )
  }

  const createEventPayload = async () => {
    const group = []
    selectedData?.map(data => {
      if (data?.type === "Group") {
        group.push(data?.id)
      }

      var arr = dataGroups?.data || []
      console.log("P##", arr)
      group?.map(data => {
        arr.map(member => {
          if (data === member?.id) {
            member?.members.map(dataMember => {
              formData?.participants.push(dataMember?.id)
            })
          }
        })
      })

      console.log("P#@!", participants)
      console.log("participantsList@##.", formData)
    })


    const uniqueParticipants = [...new Set(formData?.participants)]

    const payload = {
      ...formData,
      start_date: formData.start_date,
      start_time: getUTCTime(formData.start_date),
      end_date: formData.start_date,
      end_time: getUTCTime(formData.end_date),
      groups: group,
      participants: uniqueParticipants
    }

    return payload
  }
  const deleteEventMutation = useProductionMutation.useDeleteEventMutation()
  const handleDeleteEvent = () => {
    deleteEventMutation.mutate(
      { eventId: modalEditEventData?.id },
      {
        onSuccess: ({ data, status }) => {
          if ([204].includes(status)) {
            toast.success(`The Event have been deleted.`)
            //setShowModalAddEventOverConflicts(true)
            //setDataAddEventOverConflicts(data?.conflict_data)
            setShowModalAddEvent(false)
            refetchAllEvents?.()
          } else {
            console.error(data)
          }
        },
        onError: axiosError => {
          console.log("err")
          console.log(fieldErrorMessages(axiosError?.response?.data?.[0]))
          toast.error(
            axiosError?.response?.data?.non_field_errors?.[0] ??
            `Failed to delete event. ${fieldErrorMessages(
              axiosError?.response?.data?.[0]
            ).join(", ")}`
          )
          console.error(axiosError?.response?.data)
        }
      }
    )
  }

  const handleAddEvent = async () => {
    setLoading(true)
    const payload = await createEventPayload()
    let mutateMethod = modalEditEventData?.id
      ? confirmedUpdateEvent
      : confirmedCreateEvent
    checkEventConflictsMutation.mutate(payload, {
      onSuccess: ({ data, status }) => {

        if (status === 200) {
          setLoading(false)
          if ((data?.conflict_data || [])?.length > 0) {
            setShowModalAddEventOverConflicts(true)
            setDataAddEventOverConflicts(data?.conflict_data)
            refetchAllEvents?.()
          } else {
            mutateMethod(payload)
          }
        }
      },
      onError: axiosError => {
        console.log(axiosError)
        setLoading(false)
        toast.error(`Please fill all required fields`)
      }
    })
  }

  const getMinDateTime = () => {
    if (formData?.start_date) {
      const dateTime = new Date(formData?.start_date)
      dateTime.setMinutes(dateTime?.getMinutes() + 30)

      return dateTime
    }
    return null
  }

  const getMaxDateTime = () => {
    if (formData?.start_date) {
      const dateTime = new Date(formData?.start_date)
      dateTime.setMinutes(formData?.start_date?.getMinutes() + 30)
      dateTime.setHours(23)
      dateTime.setMinutes(30)

      return dateTime
    }

    return null
  }

  const conflictReasonHandler = (e, data) => { }

  const handleEventMutationAfterConflict = async () => {
    let mutateMethod = modalEditEventData?.id
      ? confirmedUpdateEvent
      : confirmedCreateEvent
    const payload = await createEventPayload()

    mutateMethod(payload)
  }
  var endDate = new Date()
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])
  const onMemberSelectCallback = (selected, memberIds) => {
    const auxData = utilSelectedData(activeUserTypeFilter.slug, memberIds, selectedData, selected)
    setSelectedData([...auxData])
    const groupAndUserList = utilProcessGroupAndUser(memberIds, formData.participants, formData.groups, selected, activeUserTypeFilter?.slug)
    if (activeUserTypeFilter?.slug === "Group")
      setFormDataItem("groups", [...groupAndUserList.groupList])
    setFormDataItem("participants", [...groupAndUserList.userList])

  }
  return (
    <>
      <VCenteredModal
        id={`production-calendar-add-event-over-conflicts-modal`}
        modalProps={{
          className: `modal-production-calendar modal-production-calendar-add-event-over-conflicts`,
          onHide: () => {
            setShowModalAddEventOverConflicts(false)
          },
          show: showModalAddEventOverConflicts,
          size: "md"
        }}
        modalTitle={`Scheduling over conflicts`}
        ModalFooterButtonsOnRight={() => (
          <>
            <Button variant="light" onClick={handleEventMutationAfterConflict}>
              Save
            </Button>
          </>
        )}
        closeButtonOnFooterText={"Cancel"}
      >
        {!!dataAddEventOverConflicts && (
          <>
            {dataAddEventOverConflicts?.map((addEventConflictMemberItem, i) => (
              <AddEventConflictMemberItem
                conflictReasonHandler={conflictReasonHandler}
                conflictData={addEventConflictMemberItem}
                participants={participants}
                key={i}
              />
            ))}
          </>
        )}
      </VCenteredModal>
      {loading && <ShowUpLoader />}
      <VCenteredModal
        id={`production-calendar-add-event-modal`}
        modalProps={{
          className: `modal-production-calendar w-max modal-production-calendar-add-event`,
          onHide: () => {
            setShowModalAddEvent(false)
          },
          show: showModalAddEvent,
          size: "xl"
        }}
        ModalFooterButtonsOnRight={() => (
          <>
            {!!modalEditEventData ? (
              <Button onClick={handleDeleteEvent} variant="light">
                Delete Event
              </Button>
            ) : null}
            <Button
              variant="light"
              /*disabled={roleNewMembersToAdd?.length < 1}*/
              onClick={handleAddEvent}
            >
              {!!modalEditEventData ? `Update` : `Create`} Event
            </Button>
          </>
        )}
        closeButtonOnFooterText={"Cancel"}
      >
        <Container fluid className="py-2">
          <Row>
            <Col md={5} className={"d-flex flex-column pe-4"}>
              <h3 className="fs-20 mb-0">
                {!!modalEditEventData ? `Edit` : `Add new`} event
              </h3>
              <Form.Group className="form-group">
                <Form.Label>Event Name</Form.Label>
                <Form.Control
                  name={`new-event-name`}
                  defaultValue={modalEditEventData?.name ?? ""}
                  onChange={event => {
                    setFormDataItem("name", event?.target?.value || "")
                  }}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Production</Form.Label>
                <AppFormSelect
                  ariaLabel="Select production"
                  name={`new-event-production`}
                  options={[
                    ...(dataProductions?.data
                      ?.sort((a, b) => a?.title?.localeCompare(b?.title))
                      ?.map((productionItem, i) => {
                        return {
                          value: productionItem?.id,
                          label: productionItem?.title
                        }
                      }) || [])
                  ]}
                  //selectedItem={modalEditEventData?.production?.id ?? null}
                  selectedItem={selectedProduction?.id ?? null}
                  onChangeCallback={event => {
                    // const selectItem = dataProductions?.data?.find(
                    //   productionItem =>
                    //     productionItem?.id * 1 === (event.target.value || 0) * 1
                    // )
                    setFormDataItem("production", event.target.value || "")
                  }}
                  selectFieldClassName={`form-control`}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Entry</Form.Label>
                <AppFormSelect
                  ariaLabel="Select entry option"
                  name={`new-event-entry`}
                  options={entryOptions}
                  selectedItem={
                    modalEditEventData?.entry ?? null
                    // formData?.entry ??
                    // entryOptions?.[0]?.value
                  }
                  onChangeCallback={event => {
                    setFormDataItem("entry", event.target.value)
                  }}
                  selectFieldClassName={`form-control`}
                />
              </Form.Group>
              <Form.Group className="form-group flex-grow-1 d-flex flex-column">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  className="flex-grow-1"
                  name={`new-event-description`}
                  as={"textarea"}
                  defaultValue={modalEditEventData?.description ?? ""}
                  onChange={event => {
                    setFormDataItem("description", event?.target?.value || "")
                  }}
                />
              </Form.Group>
              <Row>
                <Col xl={6} className={"ps-2"}>
                  <Form.Group className="form-group">
                    <Form.Label>Start date and time</Form.Label>
                    <DatePicker
                      className="form-control"
                      name={`new-event-start-date-time`}
                      showTimeSelect
                      dateFormat="Pp"
                      selected={formData?.start_date ?? null}
                      onChange={date => {

                        setFormDataItem("start_date", date)
                        endDate = new Date(date)
                        endDate.setMinutes(endDate.getMinutes() + 30)
                        setFormDataItem("end_date", endDate)
                      }}
                      minDate={new Date()}
                    />
                  </Form.Group>
                </Col>
                <Col xl={6}>
                  <Form.Group className="form-group">
                    <Form.Label>End date and time</Form.Label>
                    <DatePicker
                      className="form-control"
                      name={`new-event-end-date-time`}
                      showTimeSelect
                      dateFormat="Pp"
                      selected={formData?.end_date ?? null}
                      maxDate={getMaxDateTime()}
                      minDate={getMinDateTime()}
                      minTime={getMinDateTime()}
                      maxTime={getMaxDateTime()}
                      disabled={!!!formData?.start_date}
                      onChange={date => {
                        // endDate = new Date(date)
                        setFormDataItem("end_date", date)
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col md={7} className="col-right ps-4">
              <div className="user-filters mb-4">
                {userTypeFilters.map((userTypeFilterItem, i) => (
                  <Button
                    className="flex-grow-1"
                    variant={
                      userTypeFilterItem?.slug === activeUserTypeFilter?.slug
                        ? "primary"
                        : "light"
                    }
                    key={i}
                    onClick={() => {
                      setActiveUserTypeFilter(userTypeFilterItem)
                    }}
                  >
                    {userTypeFilterItem?.title}
                  </Button>
                ))}
              </div>
              <WrapperMainContentSidebar simpleBarMaxHeight="calc(100vh - (100vw * 220 / 1920))">
                <ScreenDashProductionCalendarUsersList
                  dataGroups={dataGroups}
                  activeSlug={activeUserTypeFilter?.slug}
                  memberList={
                    ["Group", "Student", "Teacher", "Parent"].includes(
                      activeUserTypeFilter?.slug
                    )
                      ? [
                        ...(activeUserTypeFilter?.slug === "Group"
                          ? dataGroups?.data || []
                          : []),
                        ...(activeUserTypeFilter?.slug === "Student"
                          ? dataAllStudents?.data || []
                          : []),
                        ...(activeUserTypeFilter?.slug === "Teacher"
                          ? dataAllStaff?.data || []
                          : []),
                        ...(activeUserTypeFilter?.slug === "Parent"
                          ? dataAllParents?.data || []
                          : [])
                      ]
                      : dataApplicationStatusByRole?.data?.roles.flatMap(x =>
                        x.members.map(y => ({ ...y, role: x.name }))
                      ) || []
                  }
                  showRoles={
                    !["Group", "Student", "Teacher", "Parent"].includes(
                      activeUserTypeFilter?.slug
                    )
                  }
                  membersSelected={[
                    ...selectedData
                  ]}
                  // groupMembers={[...(formData.groups || [])]}
                  onMemberSelectCallback={onMemberSelectCallback}
                />
              </WrapperMainContentSidebar>
            </Col>
          </Row>
        </Container>
      </VCenteredModal>
    </>
  )
}

const AddEventConflictMemberItem = ({
  conflictData,
  conflictReasonHandler,
  participants
}) => {
  const listItem = conflictData?.user_detail ?? {}
  const conflictReason = [
    { label: `Ignore conflict`, checked: true },
    { label: `Mark as excused absence`, checked: false }
  ]

  const [conflictReasons, setConflictReasons] = useState(conflictReason)

  const handleChange = value => {

    const newConflictReasons = conflictReasons.map(el => {
      let nelEl = { ...conflictReason }

      if (
        nelEl.label === `Ignore conflict` &&
        value.label === `Ignore conflict`
      ) {
        nelEl.checked = true
      } else if (
        nelEl.label === `Mark as excused absence` &&
        value.label === `Mark as excused absence`
      ) {
        nelEl.checked = false
      }
    })
    setConflictReasons(newConflictReasons)
  }
  return (
    <>
      {listItem?.map(list =>
        participants?.includes(list?.id) ? (
          <div className="add-event-conflict-member-item">
            <div className="user-list-item">
              <div
                className={`image${!!!list?.profile_picture ? " no-image" : ""
                  }`}
                data-initials={getEntityInitials(list)}
              >
                {!!list?.profile_picture && (
                  <img src={list?.profile_picture} alt="" />
                )}
              </div>
              <div className="details d-flex flex-column flex-grow-1">
                <div className="title">{getEntityFullName(list)}</div>
                <div className="type">{list?.user_type}</div>
              </div>
            </div>

            <div className="conflict-details">
              <div className="time">
                {getTimeAmPm(
                  `${conflictData?.date} ${conflictData?.start_time}`
                )}{" "}
                to{" "}
                {getTimeAmPm(`${conflictData?.date} ${conflictData?.end_time}`)}
              </div>
              <div className="title">{conflictData?.title}</div>
            </div>
            <div className="conflict-details descr">
              {conflictData?.description}
            </div>
            <div>
              {conflictReason.map((checkboxItem, i) => (
                <Form.Group
                  className="d-flex align-items-center form-group"
                  key={i}
                >
                  <Form.Check
                    //checked={checkboxItem?.checked}
                    onChange={() => handleChange(checkboxItem)}
                    name={getEntityFullName(list)}
                    defaultChecked={checkboxItem?.checked}
                    //id={list?.id}
                    type={`radio`}
                    className={`me-2`}
                  //value={checkboxItem?.label}
                  />
                  <Form.Label className="my-0">
                    {checkboxItem?.label}
                  </Form.Label>
                </Form.Group>
              ))}
            </div>
          </div>
        ) : null
      )}
    </>
  )
}

export default ProductionCalendarModalAddEvent
