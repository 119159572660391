import React, { useEffect, useRef } from "react"
import { ErrorBoundary } from "react-error-boundary"

const RoleHalfBanner = () => {
  const adContainerRef = useRef()

  useEffect(() => {
    // Load the AdButler script dynamically
    var s = document.createElement("script")

    if (!window.AdButler) {
      ;(function () {
        s.defer = true
        s.type = "text/javascript"
        s.src = "https://servedbyadbutler.com/app.js"
        s.onerror = function (event) {
          console.error("Error loading external script:", event)
        }
        var n = document.getElementsByTagName("script")[0]
        n.parentNode.insertBefore(s, n)
      })()
    }

    // Configure and create the ad placement
    var AdButler = window.AdButler || {}
    AdButler.ads = AdButler.ads || []
    var abkw = window.abkw || ""
    var plc660950 = window.plc660950 || 0

    // Create a div for ad placement
    var divId = `placement_660950_${plc660950}`
    var placementDiv = document.createElement("div")
    placementDiv.id = divId

    // Append the placement div to the container
    if (adContainerRef.current) {
      adContainerRef.current.appendChild(placementDiv)
    }

    AdButler.ads.push({
      handler: function (opt) {
        AdButler.register(186261, 660950, [234, 60], divId, opt)
      },
      opt: {
        place: plc660950++,
        keywords: abkw,
        domain: "servedbyadbutler.com",
        click: "CLICK_MACRO_PLACEHOLDER"
      }
    })
    document.body.appendChild(s)

    // s.onerror = function (event) {
    //   console.error("Error loading AdButler script:", event)
    // }

    return () => {
      document.body.removeChild(s)
    }
  }, [])

  return (
    <ErrorBoundary>
      {/* This is where the ad will be placed */}
      <div ref={adContainerRef}></div>
    </ErrorBoundary>
  )
}

export default RoleHalfBanner
