import React from "react"
import { Button } from "react-bootstrap"
import CalendarMonth from "../../components/calendar-month"
import HeaderActionsBar from "../../components/header-actions-bar"
import {
  convertLocalDateTimeToUTC,
  formatDateToFormat,
  getTimeAmPm
} from "../../utils/date"
import { useProductionCalendarContext } from "./context/production-calendar-context"
import CalendarHalfBanner from "../ads/CalendarHalfBanner"
import { parse, compareAsc } from "date-fns";

const EventItem = ({ selectedProduction, eventItemData }) => {
  // console.log(
  //   getTimeAmPm(`${eventItemData?.date} ${eventItemData?.start_time} UTC`)
  // )
  // console.log(selectedProduction)
  console.log("event........", eventItemData)
  const { setShowModalAddEvent, setModalEditEventData } =
    useProductionCalendarContext()
  return (
    <>
      {eventItemData?.production?.id === selectedProduction?.id ? (
        <div
          className="event-item cursor-pointer"
          onClick={() => {
            setModalEditEventData(eventItemData)
            setShowModalAddEvent(true)
          }}
        >
          <div className="time">
            {getTimeAmPm(
              `${eventItemData?.date} ${eventItemData?.start_time} UTC`
            )}
            <br />
            to
            <br />
            {getTimeAmPm(
              `${eventItemData?.date} ${eventItemData?.end_time} UTC`
            )}
          </div>
          <div className="details">
            <h4>{eventItemData?.name}</h4>
            <div
              className="tags"
              style={{
                width: "100%",
                /* word-wrap: break-word; */
                overflowWrap: "break-word",
                wordBreak: "break-word"
              }}
            >
              <span className="tag">{eventItemData?.description}</span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

const ScreenDashProductionCalendarSidebar = () => {
  const {
    setCurrentEMonth,
    selectedProduction,
    monthEDateRange,
    monthESelectedDate,
    setMonthESelectedDate,
    dataAllEvents,
    isFetchingAllEvents,
    setShowModalAddEvent
  } = useProductionCalendarContext()
  dataAllEvents?.data.sort((a, b) => {
    const aTime = parse(a.start_time, "HH:mm:ss", new Date())
    const bTime = parse(b.start_time, "HH:mm:ss", new Date())
    return compareAsc(aTime, bTime);
  })
  //console.log("dataAllEvents",dataAllEvents?.data)
  // console.log(monthESelectedDate)
  //console.log("show modal event.......", setShowModalAddEvent)

  return (
    <>
      <HeaderActionsBar
        className="production-calendar-main-header d-flex align-items-center"
        ButtonsLeft={() => (
          <>
            <h4 className="mb-0">Productions calendars</h4>
          </>
        )}
        ButtonsRight={() => (
          <>
            <Button
              variant="light"
              onClick={() => {
                setShowModalAddEvent(true)
              }}
            >
              Add event
            </Button>
          </>
        )}
      ></HeaderActionsBar>
      <CalendarMonth
        selectDateMode={true}
        selectDateModeDefaultSelectedDate={monthESelectedDate}
        selectDateCallback={date => {
          setMonthESelectedDate(date)
        }}
        startDate={monthEDateRange[0]}
        refDateCallback={changeObject => {
          if (!!changeObject?.refDate) setCurrentEMonth(changeObject?.refDate)
        }}
        showDotForDates={[
          ...new Set(
            dataAllEvents?.data?.map(eventItem =>
              //eventItem?.date
              eventItem?.production?.id === selectedProduction?.id
                ? formatDateToFormat(
                  `${eventItem?.date}T12:00:00Z`,
                  "YYYY-MM-DD"
                )
                : null
            )
          )
        ]}
      />
      {!!dataAllEvents && !isFetchingAllEvents && (
        <div className="events-list">
          {dataAllEvents?.data
            ?.filter(
              eventItem =>
                //eventItem?.date ===
                //formatDateToFormat(eventItem?.date, "YYYY-MM-DD") ===
                formatDateToFormat(
                  `${eventItem?.date}T12:00:00Z`,
                  "YYYY-MM-DD"
                ) ===
                formatDateToFormat(monthESelectedDate.toString(), "YYYY-MM-DD")
            )
            ?.map((eventItem, i) => (
              <EventItem
                selectedProduction={selectedProduction}
                eventItemData={eventItem}
                key={i}
              />
            ))}
        </div>
      )}
      <div style={{ marginTop: "20px" }}>
        <CalendarHalfBanner />
      </div>
    </>
  )
}
export default ScreenDashProductionCalendarSidebar
