export const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
}

export const utilSelectedData = (slug, memberIds, selectedData, selected) => {
    const isArray = Array.isArray(memberIds)
    if (!isArray) {
        const find = selectedData.findIndex((val) => val.id === memberIds && val.type === slug)
        if (find === -1) {
            let data;
            if (['cast', 'crew'].includes(slug)) {
                data = [
                    ...selectedData,
                    { id: memberIds, type: slug },
                    { id: memberIds, type: 'Student' }
                ]
            } else {
                data = [
                    ...selectedData,
                    { id: memberIds, type: slug }
                ]
            }
            return data
        } else {
            const auxData = selectedData.filter((mem) => mem.id !== memberIds)
            return [...auxData]
        }
    } else {
        const auxData = [...selectedData]

        memberIds.forEach(mem => {
            if (selected) {
                if (['cast', 'crew'].includes(slug)) {
                    auxData.push({ id: mem, type: slug }, { id: mem, type: 'Student' })
                } else {
                    auxData.push({ id: mem, type: slug })
                }

            } else {
                const find = auxData.findIndex((val) => val.id === memberIds && val.type === slug)
                auxData.splice(find, 1)
            }
        })

        return [...auxData]
    }
}

export const utilProcessGroupAndUser = (memberIds, selected_users, groups, selected, slug) => {
    const isArray = Array.isArray(memberIds)
    let array = isArray ? memberIds : [memberIds]
    const userList = selected_users ?? []
    const groupList = groups ?? []

    array.forEach(el => {
        const index = userList.indexOf(el)
        if (selected) {
            if (index < 0) {
                if (slug === "Group")
                    groupList.push(el)
                else
                    userList.push(el)
            }
        } else {
            if (index > -1) {
                userList.splice(index, 1)
            }
            if (slug === "Group") {
                const gindex = groupList.indexOf(el)
                if (gindex > -1) {
                    groupList.splice(gindex, 1)
                }
            }
        }
    })
    return {
        userList, groupList
    }
}
export const getCorrectDate = (date, time) => {
    const day = new Date(date + "T" + time + "Z");
    day.setDate(date.split("-")[2])
    return day
}

export const getCrewOrCastMembers = (dataApplicationStatusByRole, selectedData, slug) => {
    const castOrCrewMembers = []
    dataApplicationStatusByRole?.data?.roles?.forEach(role => {
        role.members.forEach(mem => {
            const find = selectedData.find((data) => data.id === mem.id && data.type === 'Student')
            if (find) {
                castOrCrewMembers.push({ id: mem.id, type: slug })
            }
        })
    })
    const auxData = new Set([...selectedData, ...castOrCrewMembers].map(JSON.stringify));
    const arrayWithOutDuplicates = Array.from(auxData).map(JSON.parse);
    return arrayWithOutDuplicates
}