import { useQuery, useMutation } from "@tanstack/react-query"
import { get, post } from "./web-api-handler"

export const useRecurlyCheck = () => {
    const { data, isFetching, refetch } = useQuery({
        queryKey: ["recurlyCheck"],
        queryFn: recurlyCheck,
    });
    const hasRecurlyAcc = data?.data?.has_guid
    return {
        hasRecurlyAcc,
        isFetching,
        hasRecurlyRefetch: refetch
    };
};



const recurlyCheck = () => {
    return get("api/v1/recurly-check")
}

export const useRecurlyGetLinks = () => {
    const { data, isFetching, refetch } = useQuery({
        queryKey: ["recurlyGetLinks"],
        queryFn: recurlyGetLinks,
    });
    return {
        links: data?.data,
        isFetchingLinks: isFetching,
        refetchLinks: refetch
    };
};


const recurlyGetLinks = () => {
    return get("api/v1/recurly-get-links")
}

export const useRecurlyPlans = () => {
    const { data, isFetching, refetch } = useQuery({
        queryKey: ["recurlyPlans"],
        queryFn: recurlyPlans,
    });
    return {
        plans: data?.data?.plans,
        isFetchingPlans: isFetching,
        refetchPlans: refetch
    };
};


const recurlyPlans = () => {
    return get("api/v1/recurly-plans")
}
export const useCreateRecurlyAccount = () => {
    return useMutation({
        mutationFn: (payload) => post('api/v1/recurly-create-account', payload)
    })
}