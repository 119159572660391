const AnchorButton = ({ href, text }) => {
    return (<a
        className="btn"
        style={{ backgroundColor: "#69040d", fontSize: 12, fontWeight: "bold", color: "white" }}
        href={href}
        target="_blank"
    >
        {text}
    </a>);
}

export default AnchorButton;