import React, { useEffect, useState, useRef, Fragment } from "react"
import { Alert, Button, Card, Form } from "react-bootstrap"
import { toast } from "react-toastify"
import HeaderActionsBar from "../../components/header-actions-bar"
import { APPCONFIG } from "../../app-config"
import { useNavigate } from "react-router-dom"

import AppFormSelect from "../../components/form/select"
import { ALLROUTES } from "../../routes"
import { useSubscriptionContext } from "./context/subscription-context"
import { useSubscriptionMutation } from "../../utils/mutations"
import { useRecurlyCheck, useCreateRecurlyAccount, useRecurlyGetLinks, useRecurlyPlans } from "../../utils/subscriptions"
import CardPlan from "./components/cardPlan"
import AnchorButton from "./components/anchorButton"
import ShowUpLoader from "../../components/ShowUpLoader"

const ScreenDashSubscriptionMain = () => {
  const savedUserData = sessionStorage.getItem(
    APPCONFIG.sessionVariables.USER_DATA
  )
  const userData = JSON.parse(savedUserData)
  const { hasRecurlyAcc, isFetching, hasRecurlyRefetch } = useRecurlyCheck()
  const { links, isFetchingLinks, refetchLinks } = useRecurlyGetLinks()
  const { plans, isFetchingPlans, refetchPlans } = useRecurlyPlans()
  const createRecurlyAccount = useCreateRecurlyAccount()
  const createAccount = () => {
    const payload = {
      nombre: userData.first_name,
      correo_electronico: userData.email
    }
    createRecurlyAccount.mutate(payload, {
      onSuccess: (resp) => {
        toast.success("Account creation successful.")
        hasRecurlyRefetch()
        refetchPlans()
        refetchLinks()
      }, onError: (error) => {
        toast.success("Oops something went wrong")
      }
    })
  }


  return (
    <>
      <HeaderActionsBar className="application-main-header"></HeaderActionsBar>
      <h4>Manage Subscription</h4>
      {!hasRecurlyAcc && !isFetching && userData?.is_head_master && <Button onClick={createAccount}>Create Recurly Account</Button>}


      {userData?.is_head_master && <div className="container">
        {hasRecurlyAcc && links && !isFetchingLinks &&
          <div className="row" style={{ marginTop: 15 }}>
            <div className="col">
              <AnchorButton href={links?.accountLink} text={"Account Details"} />
            </div>
            <div className="col">
              <AnchorButton href={links?.accountBillingInfoLink} text={"Billing Details"} />
            </div>
          </div>}
        <div className="row" style={{ marginTop: 15 }}>

          {hasRecurlyAcc && plans && !isFetchingPlans && plans.map((plan, index) =>
            <CardPlan plan={plan} key={index} />
          )}
        </div>

      </div>}
      {(isFetching || isFetchingLinks || isFetchingPlans) && <ShowUpLoader />}
    </>
  )
}
export default ScreenDashSubscriptionMain
