import React, { useEffect, useRef } from "react"

function CalendarLeaderBanner() {
  const adContainerRef = useRef(null)

  useEffect(() => {
    // Paste the provided scripts here
    if (!window.AdButler) {
      ;(function () {
        var s = document.createElement("script")
        s.async = true
        s.type = "text/javascript"
        s.src = "https://servedbyadbutler.com/app.js"
        var n = document.getElementsByTagName("script")[0]
        n.parentNode.insertBefore(s, n)
      })()
    }

    var AdButler = window.AdButler || {}
    AdButler.ads = AdButler.ads || []
    var abkw = window.abkw || ""
    var plc662489 = window.plc662489 || 0
    const adDiv = document.createElement("div")
    adDiv.id = "placement_662489_" + plc662489
    adContainerRef.current.appendChild(adDiv)
    AdButler.ads.push({
      handler: function (opt) {
        AdButler.register(
          186261,
          662489,
          [500, 70],
          "placement_662489_" + opt.place,
          opt
        )
      },
      opt: {
        place: plc662489++,
        keywords: abkw,
        domain: "servedbyadbutler.com",
        click: "CLICK_MACRO_PLACEHOLDER"
      }
    })
  }, [])

  return (
    <div>
      {/* This ref will be used to attach the ad */}
      <div ref={adContainerRef}></div>
    </div>
  )
}

export default CalendarLeaderBanner
