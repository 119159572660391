import React, { useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { format } from "date-fns"
import { useApplicationContext } from "./context/application-context"
import { useEffect } from "react"
import { convertLocalDateTimeToUTC } from "../../utils/date"
import ApplicationBanner from "../ads/ApplicationBanner"
import RoleHalfBanner from "../ads/RoleHalfBanner"
import CalendarHalfBanner from "../ads/CalendarHalfBanner"

const ScreenDashApplicationSidebar = ({ }) => {
  const {
    additionalData,
    setAdditionalData,
    activeApplicationCategoryTab,
    applicationsStatusCastList,
    applicationItems,
    setApplicationItems,
    applicationRecord,
    setApplicationRecord
  } = useApplicationContext()

  const handleCollectConflict = event => {
    const value = event.target.value === "1" ? true : false
    setApplicationRecord({ ...applicationRecord, collect_conflict: value })
    if (!value) {
      const {
        from_date,
        to_date,
        application_deadline,
        ...restOfAdditionalData
      } = applicationRecord
      setApplicationRecord({ ...restOfAdditionalData, collect_conflict: value })
    }
  }

  const onChange = dates => {
    const [start, end] = dates
    setApplicationRecord(prevData => ({
      ...prevData,
      from_date: start ? format(start, "yyyy-MM-dd") : "",
      to_date: end ? format(end, "yyyy-MM-dd") : ""
    }))
  }

  const handleDateChange = (fieldName, value) => {
    console.log(value)
    //const newDate = convertLocalDateTimeToUTC(value)
    const formattedDate = value ? format(value, "yyyy-MM-dd") : ""
    setApplicationRecord(prevData => ({
      ...prevData,
      [fieldName]: formattedDate
    }))
  }

  return (
    <>
      {activeApplicationCategoryTab.slug !== "completedApplications" ? (
        <>
          <label className="d-flex align-item-start mb-1">
            Collect Conflicts
          </label>
          <select
            className="form-select"
            aria-label="collect-conflicts"
            onChange={handleCollectConflict}
            key={applicationRecord?.collect_conflict ? 1 : 2}
            defaultValue={applicationRecord?.collect_conflict === true ? "1" : "0"}
          >
            {/* <option selected>Collect Conflicts</option> */}
            <option value={"1"}>Yes</option>
            <option value={"0"}>No</option>
          </select>
          <div>
            {applicationRecord?.collect_conflict === true && (
              <>
                <label className="d-flex align-item-start mb-1 mt-3">
                  Date range for Conflicts
                </label>
                <DatePicker

                  selected={
                    applicationRecord?.from_date
                      ? new Date(
                        convertLocalDateTimeToUTC(applicationRecord.from_date)
                      )
                      : ""
                  }
                  onChange={onChange}
                  startDate={
                    applicationRecord?.from_date
                      ? new Date(
                        convertLocalDateTimeToUTC(applicationRecord.from_date)
                      )
                      : ""
                  }
                  endDate={
                    applicationRecord?.to_date
                      ? new Date(
                        convertLocalDateTimeToUTC(applicationRecord.to_date)
                      )
                      : ""
                  }
                  minDate={new Date()}
                  selectsRange
                />
              </>
            )}
            <label className="d-flex align-item-start mb-1 mt-3">
              Application deadline
            </label>

            <DatePicker
              selected={
                applicationRecord?.application_deadline
                  ? new Date(
                    convertLocalDateTimeToUTC(
                      applicationRecord.application_deadline
                    )
                  )
                  : applicationRecord?.application_deadline
                    ? new Date(
                      convertLocalDateTimeToUTC(
                        applicationRecord?.application_deadline
                      )
                    )
                    : ""
              }
              onChange={date => handleDateChange("application_deadline", date)}
            />
          </div>
          <div style={{ marginTop: "60%" }}>
            <div style={{ marginTop: "20px" }}>
              <ApplicationBanner />
            </div>
            <div style={{ marginTop: "20px" }}>
              <RoleHalfBanner />
            </div>
            <div style={{ marginTop: "20px" }}>
              <CalendarHalfBanner />
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}
export default ScreenDashApplicationSidebar
