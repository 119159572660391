import React, { useEffect, useState } from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import DatePicker from "react-datepicker"

import VCenteredModal from "../../components/modal/vcentered-modal"
import WrapperMainContentSidebar from "../../components/wrappers/dashboard/main-content-sidebar"
import { convertLocalDateTimeToUTC, formatDateToFormat, getUTCTime } from "../../utils/date"
import { useProductionCalendarContext } from "./context/production-calendar-context"
import ScreenDashProductionCalendarUsersList from "./production-calendar-users-list"
import { useAppContext } from "../../contexts/app-context"
import { useProductionsQueries } from "../../utils/queries"
import { useProductionMutation } from "../../utils/mutations"
import { toast } from "react-toastify"
import { fieldErrorMessages } from "../../utils/common"
import { capitalizeFirstLetter, utilSelectedData, utilProcessGroupAndUser, getCorrectDate, getCrewOrCastMembers } from "./utils/utils"

import ShowUpLoader from "../../components/ShowUpLoader"

const ProductionCalendarModalAddConflict = () => {
  const { isUserLoggedIn, selectedProduction, dataAllStaff, dataAllStudents } =
    useAppContext()
  const {
    dataGroups,
    refetchProductionConflicts,
    showModalAddConflict,
    setShowModalAddConflict,
    modalConflictDetails,
    setShowModalConflictDetails,
    setModalConflictDetails
  } = useProductionCalendarContext()
  const [loading, setLoading] = useState(false)
  const [selectedData, setSelectedData] = useState([])
  console.log("isloging......", isUserLoggedIn)
  const deleteConflictMutation =
    useProductionMutation.useDeleteConflictMutation()

  const handleDeleteConflict = () => {
    deleteConflictMutation.mutate(
      { conflictId: modalConflictDetails?.id },
      {
        onSuccess: ({ data, status }) => {
          if ([204].includes(status)) {
            toast.success(`The conflict have been deleted.`)
            setShowModalConflictDetails(false)
            setModalConflictDetails(null)
            setShowModalAddConflict(false)
            refetchProductionConflicts?.()
          } else {
            console.error(data)
          }
        },
        onError: axiosError => {
          console.log("err")
          console.log(fieldErrorMessages(axiosError?.response?.data?.[0]))
          toast.error(
            axiosError?.response?.data?.non_field_errors?.[0] ??
            `Failed to delete conflict. ${fieldErrorMessages(
              axiosError?.response?.data?.[0]
            ).join(", ")}`
          )
          console.error(axiosError?.response?.data)
        }
      }
    )
  }

  const userTypeFilters = [
    {
      slug: "Student",
      title: "Students"
    },
    {
      slug: "Teacher",
      title: "Teachers"
    },
    {
      slug: "Group",
      title: "Groups"
    },
    {
      slug: "cast",
      title: "Cast"
    },
    {
      slug: "crew",
      title: "Crew"
    }
  ]
  const [activeUserTypeFilter, setActiveUserTypeFilter] = useState(
    userTypeFilters[0]
  )
  let formDataInitialValue = {
    user: [],
    production: selectedProduction?.id
  }


  if (modalConflictDetails?.id) {
    formDataInitialValue = {
      ...formDataInitialValue,
      ...modalConflictDetails,
      production: selectedProduction?.id,
      start_date: getCorrectDate(modalConflictDetails?.date, modalConflictDetails?.start_time),
      end_date: getCorrectDate(modalConflictDetails?.date, modalConflictDetails?.end_time),
      selected_users: [
        ...(modalConflictDetails?.user_detail?.map(
          memberItem => memberItem?.id
        ) ?? [])
      ],
      groups: modalConflictDetails.groups?.map(user => user) || []
    }
  }
  const [formData, setFormData] = useState(formDataInitialValue)
  useEffect(() => {
    !showModalAddConflict && setSelectedData([])
  }, [showModalAddConflict]);
  useEffect(() => {
    if (modalConflictDetails) {
      const groups = modalConflictDetails?.groups?.map(gr => { return { id: gr, type: "Group" } })
      const userDetail = modalConflictDetails?.user_detail?.map(par => { return { id: par.id, type: capitalizeFirstLetter(par.user_type) } })
      setSelectedData([...groups, ...userDetail])
    } else {
      setSelectedData([])
    }
  }, [modalConflictDetails])
  const {
    data: dataApplicationStatusByRole,
    isFetching: isFetchingApplicationStatusByRole
  } = useProductionsQueries.useApplicationStatusByRoleQuery({
    enabled: isUserLoggedIn && !!showModalAddConflict,
    productionId: selectedProduction?.id,
    rolesType: activeUserTypeFilter?.slug
  })

  useEffect(() => {
    if (['cast', 'crew'].includes(activeUserTypeFilter?.slug)) {
      const arrayWithOutDuplicates = getCrewOrCastMembers(dataApplicationStatusByRole, selectedData, activeUserTypeFilter?.slug)
      setSelectedData([...arrayWithOutDuplicates])
    }
  }, [dataApplicationStatusByRole])
  useEffect(() => {
    if (showModalAddConflict === true) {
      setFormData(formDataInitialValue) // reset data on open/close of modal
    }
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [showModalAddConflict])

  const setFormDataItem = (fieldName, value) => {
    setFormData?.(prevData => ({
      ...prevData,
      [fieldName]: value,
      ...(fieldName === "start_date"
        ? {
          date: formatDateToFormat(value, "YYYY-MM-DD"),
          start_time: formatDateToFormat(value, "hh:mm")
        }
        : {}),
      ...(fieldName === "end_date"
        ? {
          end_time: formatDateToFormat(value, "hh:mm")
        }
        : {})
    }))
  }

  const addConflictMutation = useProductionMutation.useAddConflictMutation()
  const updateConflictMutation =
    useProductionMutation.useUpdateConflictMutation()
  const handleAddConflict = () => {
    setLoading(true)
    const mutateMethod = modalConflictDetails?.id
      ? updateConflictMutation.mutate
      : addConflictMutation.mutate

    const start_date_UTC = convertLocalDateTimeToUTC(formData?.start_date)
    const end_date_UTC = convertLocalDateTimeToUTC(formData?.end_date)
    const group = []

    selectedData?.map(data => {
      if (
        data?.type === "Group"
      ) {
        group.push(data?.id)
      }
      const arr = dataGroups?.data || []
      group?.map(data => {
        arr.map(member => {
          if (data === member?.id) {
            member?.members.map(dataMember => {
              if (dataMember.user_type?.toLowerCase() !== "parent") {
                formData?.selected_users.push(dataMember?.id)
              }
            })
          }
        })
      })
    })
    const uniqueParticipants = [...new Set(formData?.selected_users)]
    const payload = [
      {
        ...formData,
        user: uniqueParticipants,
        groups: group,
        start_time: getUTCTime(formData.start_date),
        end_time: getUTCTime(formData.end_date)
      }
    ]

    mutateMethod(payload, {
      onSuccess: ({ data, status }) => {
        if ([200, 201].includes(status)) {
          setLoading(false)
          toast.success(
            `The conflict have been ${modalConflictDetails?.id ? "updated" : "added"
            }.`
          )
          setShowModalConflictDetails(false)
          setModalConflictDetails(null)
          setShowModalAddConflict(false)
          refetchProductionConflicts?.()
        } else {
          setLoading(false)
          console.error(data)
        }
      },
      onError: axiosError => {
        setLoading(false)
        toast.error(`Please fill all required fields`)
        console.error(axiosError?.response?.data)
      }
    })
  }

  const getMinDateTime = () => {
    if (formData?.start_date) {
      const dateTime = new Date(formData?.start_date)
      dateTime.setMinutes(dateTime.getMinutes() + 30)

      return dateTime
    }
    return null
  }

  const getMaxDateTime = () => {
    if (formData?.start_date) {
      const dateTime = new Date(formData?.start_date)
      dateTime.setMinutes(formData?.start_date.getMinutes() + 30)
      dateTime.setHours(23)
      dateTime.setMinutes(30)

      return dateTime
    }

    return null
  }
  const onMemberSelectCallback = (selected, memberIds) => {
    const auxData = utilSelectedData(activeUserTypeFilter.slug, memberIds, selectedData, selected)
    setSelectedData([...auxData])
    const groupAndUserList = utilProcessGroupAndUser(memberIds, formData.selected_users, formData.groups, selected, activeUserTypeFilter?.slug)
    if (activeUserTypeFilter?.slug === "Group")
      setFormDataItem("groups", [...groupAndUserList.groupList])
    setFormDataItem("selected_users", [...groupAndUserList.userList])
  }
  return (
    <VCenteredModal
      id={`production-calendar-add-conflict-modal`}
      modalProps={{
        className: `modal-production-calendar w-max modal-production-calendar-add-conflict`,
        onHide: () => {
          setShowModalAddConflict(false)
        },
        show: showModalAddConflict,
        size: "xl"
      }}
      ModalFooterButtonsOnRight={() => (
        <>
          {!!modalConflictDetails ? (
            <Button onClick={handleDeleteConflict} variant="light">
              Delete Conflict
            </Button>
          ) : null}
          <Button
            variant="light"
            /*disabled={roleNewMembersToAdd?.length < 1}*/
            onClick={handleAddConflict}
          >
            {!!modalConflictDetails ? "Update" : "Create"} Conflict
          </Button>
        </>
      )}
      closeButtonOnFooterText={"Cancel"}
    >
      {loading && <ShowUpLoader />}
      <Container fluid className="py-2">
        <Row>
          <Col md={5} className={"d-flex flex-column pe-4"}>
            <h3 className="fs-20 mb-0">
              {!!modalConflictDetails ? "Edit" : "Add"} new conflict
            </h3>
            <Form.Group className="form-group">
              <Form.Label>Conflict Name</Form.Label>
              <Form.Control
                name={`new-conflict-name`}
                defaultValue={modalConflictDetails?.title ?? ""}
                onChange={event => {
                  setFormDataItem("title", event?.target?.value || "")
                }}
              />
            </Form.Group>
            <Form.Group className="form-group flex-grow-1 d-flex flex-column">
              <Form.Label>Description</Form.Label>
              <Form.Control
                className="flex-grow-1"
                name={`new-conflict-description`}
                as={"textarea"}
                defaultValue={modalConflictDetails?.description ?? ""}
                onChange={event => {
                  setFormDataItem("description", event?.target?.value || "")
                }}
              />
            </Form.Group>
            <Row>
              <Col xl={6} className={"ps-2"}>
                <Form.Group className="form-group">
                  <Form.Label>Start date and time</Form.Label>
                  <DatePicker
                    className="form-control"
                    name={`new-conflict-start-date-time`}
                    showTimeSelect
                    dateFormat="Pp"
                    selected={formData?.start_date ?? null}
                    onChange={date => {
                      setFormDataItem("start_date", date)
                      var endDate = new Date(date)
                      endDate.setMinutes(endDate.getMinutes() + 30)
                      setFormDataItem("end_date", endDate)
                    }}
                    minDate={new Date()}
                  />
                </Form.Group>
              </Col>
              <Col xl={6}>
                <Form.Group className="form-group">
                  <Form.Label>End date and time</Form.Label>
                  <DatePicker
                    className="form-control"
                    name={`new-conflict-end-date-time`}
                    showTimeSelect
                    dateFormat="Pp"
                    selected={formData?.end_date ?? null}
                    maxDate={getMaxDateTime()}
                    minDate={getMinDateTime()}
                    minTime={getMinDateTime()}
                    maxTime={getMaxDateTime()}
                    disabled={!!!formData?.start_date}
                    onChange={date => {
                      setFormDataItem("end_date", date)
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col md={7} className="col-right ps-4">
            <div className="user-filters mb-4">
              {userTypeFilters.map((userTypeFilterItem, i) => (
                <Button
                  className="flex-grow-1"
                  variant={
                    userTypeFilterItem?.slug === activeUserTypeFilter?.slug
                      ? "primary"
                      : "light"
                  }
                  key={i}
                  onClick={() => {
                    setActiveUserTypeFilter(userTypeFilterItem)
                  }}
                >
                  {userTypeFilterItem?.title}
                </Button>
              ))}
            </div>
            <WrapperMainContentSidebar simpleBarMaxHeight="calc(100vh - (100vw * 220 / 1920))">
              <ScreenDashProductionCalendarUsersList
                activeSlug={activeUserTypeFilter?.slug}
                memberList={
                  ["Group", "Student", "Teacher"].includes(
                    activeUserTypeFilter?.slug
                  )
                    ? [
                      ...(activeUserTypeFilter?.slug === "Group"
                        ? dataGroups?.data || []
                        : []),

                      ...(activeUserTypeFilter?.slug === "Student"
                        ? dataAllStudents?.data || []
                        : []),
                      ...(activeUserTypeFilter?.slug === "Teacher"
                        ? dataAllStaff?.data || []
                        : [])
                    ]
                    : dataApplicationStatusByRole?.data?.roles.flatMap(x =>
                      x.members.map(y => ({ ...y, role: x.name }))
                    ) || []
                }
                showRoles={
                  !["Group", "Student", "Teacher", "Parent"].includes(
                    activeUserTypeFilter?.slug
                  )
                }
                membersSelected={[
                  ...selectedData
                ]}
                onMemberSelectCallback={onMemberSelectCallback}
              />
            </WrapperMainContentSidebar>
          </Col>
        </Row>
      </Container>
    </VCenteredModal>
  )
}
export default ProductionCalendarModalAddConflict
